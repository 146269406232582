import { useQuery } from '@tanstack/vue-query'

export function useTaxTypes() {
    const { execute } = useApi(`/api/tax-types`, 'GET')

    const { data } = useQuery({
        queryKey: ['tax-types'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { taxTypes: data, execute }
}
